/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Mada_200ExtraLight = require('./Mada_200ExtraLight.ttf');
export const Mada_300Light = require('./Mada_300Light.ttf');
export const Mada_400Regular = require('./Mada_400Regular.ttf');
export const Mada_500Medium = require('./Mada_500Medium.ttf');
export const Mada_600SemiBold = require('./Mada_600SemiBold.ttf');
export const Mada_700Bold = require('./Mada_700Bold.ttf');
export const Mada_900Black = require('./Mada_900Black.ttf');
